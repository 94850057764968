import type { ReactNode } from 'react';

import {
  AlertsProvider,
  SidebarProvider,
  TabsProvider
} from '@angloeastern/react-library';
import { Provider as UserProvider } from '@context/AuthUser';
import { Provider as ConfigProvider } from '@context/Config';
import { Provider as FavoriteProvider } from '@context/Favorite';
import { Provider as FeatureTogglesProvider } from '@context/FeatureToggles';
import { Provider as NotificationsProvider } from '@context/Notifications';
import { Provider as OrgProvider } from '@context/Organizations';
import { Provider as PageIDProVider } from '@context/PageID';
import { Provider as ProfileProvider } from '@context/Profile';
import { Provider as VesselProvider } from '@context/Vessels';
import { Provider as ViewProvider } from '@context/View';
import AppRouter from '../../AppRouter';
import AppWrapper from '../../AppWrapper';

const AuthorizedShell = ({ children }: { children: ReactNode }) => {
  return (
    <FeatureTogglesProvider>
      <ConfigProvider>
        <ProfileProvider>
          <UserProvider>
            <PageIDProVider>
              <SidebarProvider>
                <VesselProvider>
                  <OrgProvider>
                    <FavoriteProvider>
                      <ViewProvider>
                        <NotificationsProvider>
                          <AlertsProvider>
                            <TabsProvider>
                              <AppWrapper>
                                <AppRouter>{children}</AppRouter>
                              </AppWrapper>
                            </TabsProvider>
                          </AlertsProvider>
                        </NotificationsProvider>
                      </ViewProvider>
                    </FavoriteProvider>
                  </OrgProvider>
                </VesselProvider>
              </SidebarProvider>
            </PageIDProVider>
          </UserProvider>
        </ProfileProvider>
      </ConfigProvider>
    </FeatureTogglesProvider>
  );
};

export default AuthorizedShell;
