import { apiPut } from '@api/callAPI';

const useUpdateLastAccess = () => {
  const updateLastAccess = () => apiPut(`/profile/last-access`);

  return {
    updateLastAccess
  };
};

export default useUpdateLastAccess;
