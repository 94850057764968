import { apiGet, apiPut } from '@api/callAPI';
import type { PermissionParams, ProfileData } from '@context/Profile/types';

export const fetchProfile = (signal?: AbortSignal) =>
  apiGet(`/profile`, false, { signal });

export const updateProfile = (payload: ProfileData, signal?: AbortSignal) =>
  apiPut(`/profile`, payload, false, { signal });

export const updatePassword = (
  currentPassword: string,
  newPassword: string,
  signal?: AbortSignal
) =>
  apiPut('/profile/password', { currentPassword, newPassword }, false, {
    signal
  });

export const fetchUserDetails = (signal?: AbortSignal) =>
  apiGet(`/profile/userDetails`, false, { signal });

export const fetchPermissions = (
  { organizationAeCode, roleId }: PermissionParams,
  signal?: AbortSignal
) =>
  apiGet(`/profile/permissions/${organizationAeCode}/${roleId}`, false, {
    signal
  });
