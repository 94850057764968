import { useContext, useEffect, useMemo } from 'react';

import { useVessels } from '@context/Vessels';
import type { Vessel } from '@context/Vessels/types';
import createDataContext from '@context/createDataContext';
import {
  clearActiveItem,
  clearSelection,
  createFavorite,
  fetchFavorites,
  hideToast,
  removeFavorite,
  select,
  setActiveItem,
  setDirty,
  setDisabled,
  setIsFormOpen,
  setLoading,
  showToast,
  unselect,
  updateFavorite
} from './actions';
import reducer from './reducer';
import type { Favorite, State } from './types';

export const initActiveItem: Favorite = {
  favoriteId: undefined,
  title: undefined,
  vesselAeCodes: []
};

const initialState: State = {
  isOpen: false,
  dirty: false,
  toast: '',
  status: undefined,
  isDisabled: false,
  isLoading: false,
  disabledToolTip: '',
  selected: undefined,
  activeItem: initActiveItem,
  favorites: undefined
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    unselect,
    fetchFavorites,
    removeFavorite,
    updateFavorite,
    createFavorite,
    select,
    setDirty,
    setLoading,
    showToast,
    hideToast,
    setIsFormOpen,
    setDisabled,
    setActiveItem,
    clearActiveItem,
    clearSelection
  },
  initialState
);

// Separate hook for orgAeCodes calculation
export const useOrgAeCodes = () => {
  const {
    state: { allVessels }
  } = useVessels();
  const ctx = useContext<any>(Context);
  const {
    state: { selected }
  } = ctx;

  return useMemo(() => {
    if (!allVessels?.length || !selected?.vesselAeCodes?.length) {
      return [];
    }
    return Array.from(
      new Set(
        allVessels
          .filter((v: Vessel) => selected.vesselAeCodes.includes(v.aeCode))
          .map((v: Vessel) => v.organizationAeCode)
      )
    );
  }, [allVessels, selected?.vesselAeCodes]);
};

export const useFavorite = () => {
  const {
    state: { favorite },
    setFavorite
  } = useVessels();

  const ctx = useContext<any>(Context);
  const {
    state: { activeItem, selected, favorites },
    setDirty,
    setActiveItem,
    select
  } = ctx;

  const exists = useMemo(
    () => (title: string) =>
      favorites?.some((fav: Favorite) => fav.title === title),
    [favorites]
  );

  const orgAeCodes = useOrgAeCodes();

  // Monitor changes to determine if form is dirty
  useEffect(() => {
    if (!activeItem || !selected) {
      setDirty(false);
      return;
    }

    interface DirtyCheckConditions {
      newFavorite: boolean;
      hasVessels: boolean;
      existingFavorite: boolean;
      titleChanged: boolean;
      vesselCountChanged: boolean;
      vesselsChanged: boolean;
    }

    const conditions: DirtyCheckConditions = {
      newFavorite: !selected.favoriteId,
      hasVessels: activeItem.vesselAeCodes?.length > 0,
      existingFavorite: Boolean(activeItem.favoriteId),
      titleChanged:
        (activeItem.title || '').toUpperCase() !==
        (selected.title || '').toUpperCase(),
      vesselCountChanged:
        activeItem.vesselAeCodes?.length !== selected.vesselAeCodes?.length,
      vesselsChanged: !activeItem.vesselAeCodes?.every((x: string) =>
        selected.vesselAeCodes?.includes(x)
      )
    };

    const isDirty: boolean =
      (conditions.newFavorite && conditions.hasVessels) ||
      (conditions.existingFavorite &&
        (conditions.titleChanged ||
          conditions.vesselCountChanged ||
          conditions.vesselsChanged));

    setDirty(isDirty);
  }, [activeItem?.title, activeItem?.vesselAeCodes, selected]);

  // Handle favorite selection
  useEffect(() => {
    if (!favorites?.length || favorite || !activeItem?.title) {
      return;
    }

    interface FavoriteItem {
      title: string;
      favoriteId?: string;
      vesselAeCodes: string[];
    }
    const item: FavoriteItem | undefined = favorites.find(
      (x: FavoriteItem) => x.title === activeItem.title
    );
    if (item) {
      setFavorite(item);
      setActiveItem(item);
      select(item);
    }
  }, [favorites, favorite, activeItem?.title]);

  return { ...ctx, exists, orgAeCodes };
};
