import axios from 'axios';

import { SM_TOKEN } from '@context/IDSAuth/constants';
import { CONFIG } from '@library/config';
import { refreshTokenPost } from '@utils/refreshTokenPost';
import { trackException } from '../../appinsights/index';

const {
  SVC_URL: baseURL,
  REQUEST_TIMEOUT: timeout = '180000',
  IDS_URI,
  HOST
} = CONFIG;
const BUFFER = 30000;

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}> = [];

const processQueue = (error: Error | null, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleUnauthorized = () => {
  localStorage.removeItem(SM_TOKEN);

  window.location.href = `${IDS_URI}/account/logout?post_logout_redirect_uri=${HOST}`;
};

const createHttpInstance = () => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      credentials: 'include'
    },
    timeout: parseInt(timeout, 10)
  });

  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) =>
      Promise.reject(error instanceof Error ? error : new Error(String(error)))
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status !== 401) {
        const json = error?.toJSON?.();
        const exception = {
          status: json?.status || 'Unknown Status',
          message: error?.message || 'Unknown Message',
          url: error?.config?.url || 'Unknown URL',
          lineNumber: 0,
          columnNumber: 0,
          error: json || error,
          stackDetails: error?.stack
        };

        trackException(exception);
        return Promise.reject(
          error instanceof Error ? error : new Error(String(error))
        );
      }

      if (
        error.response?.status === 401 &&
        originalRequest &&
        !originalRequest._retry
      )
        return;
      if (isRefreshing) {
        // Wait for the token refresh
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
            }
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshTokenPost('myae_ship_ui');
        const newAccessToken = newToken?.access_token;
        if (newAccessToken) {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          processQueue(null, newAccessToken);
          isRefreshing = false;
          return instance(originalRequest);
        }
        processQueue(new Error('Failed to refresh token'));
        isRefreshing = false;
        handleUnauthorized();
        return Promise.reject(
          new Error('Session expired - Redirecting to login')
        );
      } catch (error) {
        processQueue(new Error('Failed to refresh token'));
        isRefreshing = false;
        handleUnauthorized();
        return Promise.reject(
          new Error('Session expired - Redirecting to login')
        );
      }
    }
  );

  return instance;
};

const http = createHttpInstance();

export const createAbortController = () => new AbortController();

export default http;
