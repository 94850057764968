import { StrictMode, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@angloeastern/react-library';
import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { IDSAuthProvider } from '@context/IDSAuth';
import App from './app/App';

import { CONFIG } from '@library/config';
import 'animate.css';
import '../promise-pollyfil';
import './i18n';
import './index.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  const app = (
    <StrictMode>
      <Suspense
        fallback={
          <ThemeProvider>
            <BlueWorldMapLoader />
          </ThemeProvider>
        }
      >
        <Router>
          <CookiesProvider
            defaultSetOptions={{
              path: '/',
              sameSite: CONFIG.IS_PROD ? 'none' : 'lax'
            }}
          >
            <IDSAuthProvider>
              <App />
            </IDSAuthProvider>
          </CookiesProvider>
        </Router>
      </Suspense>
    </StrictMode>
  );

  root.render(app);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
