import type { ReactNode } from 'react';
import { Fragment, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthRoute from '@routes/AuthRoute';
import Home from '@routes/Home';
import { getSupportedLanguages } from '@utils/index';
import Providers from './Providers';
import { SetupPDFJS } from './SetupPDFjs';

const Accounts = lazy(() => import('@routes/Accounts'));
const Certificates = lazy(() => import('@routes/Certificates'));
const Crew = lazy(() => import('@routes/Crew'));
const Documents = lazy(() => import('@routes/Documents'));
const Drawings = lazy(() => import('@routes/Drawings'));
const Kpis = lazy(() => import('@routes/Kpis'));
const Location = lazy(() => import('@routes/Location'));
const NotFound = lazy(() => import('@routes/NotFound'));
const OpexSupportings = lazy(() => import('@routes/OpexSupportings'));
const OurExperts = lazy(() => import('@routes/OurExperts'));
const Reports = lazy(() => import('@routes/Reports'));
const WhatsNew = lazy(() => import('@routes/WhatsNew'));
const WorldMap = lazy(() => import('@routes/WorldMap'));
const Logout = lazy(() => import('@routes/Logout'));

SetupPDFJS();

const RestrictedArea = () => {
  const withProvidersAndAuth = (Component: ReactNode) => (
    <AuthRoute>
      <Providers>{Component}</Providers>
    </AuthRoute>
  );

  return (
    <Routes>
      <Route
        path={'/callback'}
        element={
          <AuthRoute>
            <></>
          </AuthRoute>
        }
      />
      <Route path={'/logout'} element={<Logout />} />
      <Route
        path="*"
        element={
          <Routes>
            <Route index path="/" element={withProvidersAndAuth(<Home />)} />
            {getSupportedLanguages().map((locale: string) => {
              const lang = locale === 'en' ? '' : `/${locale}`;
              return (
                <Fragment key={locale}>
                  <Route
                    path={`/${locale}`}
                    element={withProvidersAndAuth(<Home />)}
                  />
                  <Route
                    path={`${lang}/location`}
                    element={withProvidersAndAuth(<Location />)}
                  />
                  <Route
                    path={`${lang}/worldmap`}
                    element={withProvidersAndAuth(<WorldMap />)}
                  />
                  <Route
                    path={`${lang}/ourexperts`}
                    element={withProvidersAndAuth(<OurExperts />)}
                  />
                  <Route
                    path={`${lang}/opex-supporting/:vesselAeCode/:transactionId`}
                    element={withProvidersAndAuth(<OpexSupportings />)}
                  />
                  <Route
                    path={`${lang}/whatsnew`}
                    element={withProvidersAndAuth(<WhatsNew />)}
                  />
                  <Route
                    path={`${lang}/crew`}
                    element={withProvidersAndAuth(<Crew />)}
                  />
                  <Route
                    path={`${lang}/documents`}
                    element={withProvidersAndAuth(<Documents />)}
                  />
                  <Route
                    path={`${lang}/accounts`}
                    element={withProvidersAndAuth(<Accounts />)}
                  />
                  <Route
                    path={`${lang}/certificates`}
                    element={withProvidersAndAuth(<Certificates />)}
                  />
                  <Route
                    path={`${lang}/reports`}
                    element={withProvidersAndAuth(<Reports />)}
                  />
                  <Route
                    path={`${lang}/drawings`}
                    element={withProvidersAndAuth(<Drawings />)}
                  />
                  <Route
                    path={`${lang}/kpi`}
                    element={withProvidersAndAuth(<Kpis />)}
                  />
                  <Route path={`${lang}/*`} element={<NotFound />} />
                </Fragment>
              );
            })}
          </Routes>
        }
      />
    </Routes>
  );
};

export default RestrictedArea;
