import { getSupportedLanguages } from '@utils/index';
import { Fragment, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const ErrorPage = lazy(() => import('@routes/ErrorPage'));
const Forbidden = lazy(() => import('@routes/Forbidden'));

const PublicArea = () => {
  return (
    <Routes>
      {getSupportedLanguages().map((locale: string) => (
        <Fragment key={locale}>
          <Route
            key={locale}
            path={`/${locale === 'en' ? '' : locale}/error`}
            element={<ErrorPage />}
          />
          <Route
            key={locale}
            path={`/${locale === 'en' ? '' : locale}/forbidden`}
            element={<Forbidden />}
          />
        </Fragment>
      ))}

      {/* This is a "pass-through" route that allows the root path and other paths
          to be handled by RestrictedArea without throwing "no routes matched" error */}
      <Route path="*" element={null} />
    </Routes>
  );
};

export default PublicArea;
