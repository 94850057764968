import { AUTH_STORAGE_KEY } from '@context/IDSAuth/constants';
import { CONFIG } from '@library/config';

export const useLogout = () => {
  const handleLogout = () => {
    const params = new URLSearchParams({
      post_logout_redirect_uri: `${CONFIG.HOST}/logout`
    });

    localStorage.removeItem(AUTH_STORAGE_KEY.IS_AUTHENTICATED);

    window.location.replace(
      `${CONFIG.IDS_URI}/account/logout?${params.toString()}`
    );
  };

  return {
    handleLogout
  };
};
