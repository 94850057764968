import { CONFIG } from '@library/config';

const { HOST } = CONFIG;

export const refreshTokenPost = async (clientId: string) => {
  try {
    const params = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: clientId
    });

    const response = await fetch(`${HOST}/api/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: params.toString()
    });

    if (!response.ok) {
      throw new Error('Refresh token failed');
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return null;
  }
};
