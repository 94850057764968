import { useCallback } from 'react';

import { useIDSAuth } from '@context/IDSAuth';

const useFreshDesk = () => {
  const { user } = useIDSAuth();
  const w = window as any;

  const fillForm = (name: string, email: string) => {
    w?.FreshworksWidget('identify', 'ticketForm', {
      name,
      email
    });
  };

  const hideForm = () => w?.FreshworksWidget('hide');
  const showForm = () => w?.FreshworksWidget('open');
  const hideLauncher = () => w?.FreshworksWidget('hide', 'launcher');
  const showLauncher = () => w?.FreshworksWidget('show', 'launcher');

  const preFillForm = useCallback(() => {
    if (!user) return;
    fillForm(user.fullName, user.email);
  }, [user]);

  return {
    fillForm,
    hideForm,
    showForm,
    preFillForm,
    hideLauncher,
    showLauncher
  };
};

export default useFreshDesk;
