import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { useIDSAuth } from '@context/IDSAuth';
import { fetchUserDetails } from '@context/Profile/api';
import useFreshDesk from '@hooks/useFreshDesk';
import useUpdateLastAccess from '@hooks/useUpdateLastAccess';
import { stateBase64Decode } from '@utils/pkce';

const getSavedPath = (state: string) => {
  try {
    const decodedState = JSON.parse(stateBase64Decode(state || ''));
    return decodedState.returnTo ?? '/';
  } catch (e) {
    console.warn('Could not parse state parameter', e);
    return '/';
  }
};

interface Props {
  children: ReactNode;
}

const AuthRoute = ({ children }: Props) => {
  const {
    handleAuthorization,
    isAuthenticated,
    handleCallback,
    handleError,
    setAuth,
    error,
    refreshAccessToken
  } = useIDSAuth();
  const location = useLocation();
  const { preFillForm: setupFreshdesk } = useFreshDesk();
  const { updateLastAccess } = useUpdateLastAccess();

  const navigate = useNavigate();
  const { search } = location;
  const isProcessingAuth = useRef(false);
  const hasLoadUserDetailRef = useRef(false);
  const hasSetFreshdesk = useRef(false);
  const hasUpdatedLastAccess = useRef(false);
  const params = new URLSearchParams(search);

  const onExchangeToken = useCallback(async () => {
    if (isProcessingAuth.current) return;

    const code = params.get('code');
    const state = params.get('state');

    if (!code || !state) return;

    try {
      isProcessingAuth.current = true;
      const returnTo = getSavedPath(state);
      await handleCallback(code, state);

      navigate(returnTo, { replace: true });
    } catch (error) {
      if ((error as Error)?.message === 'FORBIDDEN') {
        navigate('/forbidden');
      }

      if (error instanceof Error) {
        handleError(error);
      }
    } finally {
      isProcessingAuth.current = false;
    }
  }, [handleCallback, search, navigate, handleError]);

  const handleAuth = useCallback(async () => {
    if (isProcessingAuth.current) return;

    if (params.get('error') === 'login_required') {
      const returnTo = getSavedPath(params.get('state') ?? '');
      handleAuthorization(returnTo ?? location.pathname, 'login');
      return;
    }
    if (location.pathname === '/callback' && !isAuthenticated) {
      await onExchangeToken();
      return;
    }

    handleAuthorization(location.pathname, 'none');
    return;
  }, [handleAuthorization, onExchangeToken, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated || error) return;

    handleAuth();
  }, [
    location.pathname,
    isAuthenticated,
    error,
    handleAuthorization,
    onExchangeToken,
    error,
    refreshAccessToken
  ]);

  const callUserDetails = useCallback(async () => {
    try {
      const { firstName, lastName, email, role } = await fetchUserDetails();
      setAuth({
        isAuthenticated: true,
        isLoading: false,
        error: '',
        user: {
          firstName,
          lastName,
          fullName: `${firstName} ${lastName}`,
          email,
          roleName: role?.name
        }
      });
    } catch (error: unknown) {
      handleError(error as Error);
    } finally {
      hasLoadUserDetailRef.current = true;
    }
  }, [setAuth, handleError]);

  useEffect(() => {
    if (isAuthenticated && !hasLoadUserDetailRef.current) {
      callUserDetails();
    }
  }, [isAuthenticated]);

  if (isProcessingAuth.current) return <BlueWorldMapLoader />;
  if (!isAuthenticated) return null;

  if (isAuthenticated && !hasSetFreshdesk.current) {
    setupFreshdesk();
    hasSetFreshdesk.current = true;
  }
  if (isAuthenticated && !hasUpdatedLastAccess.current) {
    updateLastAccess();
    hasUpdatedLastAccess.current = true;
  }

  return <>{children}</>;
};

export default AuthRoute;
