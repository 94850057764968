import { useCallback, useContext, useMemo } from 'react';

import { useAuthUser } from '@context/AuthUser';
import type { Vessel } from '@context/Vessels/types';
import createDataContext from '../createDataContext';
import * as actions from './actions';
import { vesselImage } from './api';
import vesselReducer from './reducer';
import type { State } from './types';

const initialState: State = {
  allVessels: null,
  favorite: null,
  vessels: null,
  vessel: null,
  error: false
};

export const { Context, Provider } = createDataContext(
  vesselReducer,
  {
    ...actions
  },
  initialState
);

export const useVessels = () => {
  const ctx = useContext<any>(Context);
  const { vesselCodes, roleName } = useAuthUser();
  const { state } = ctx;
  const { vessel, vessels, allVessels } = state;

  const vesselAvatar = useCallback(
    async (signal?: AbortSignal) => {
      if (!vessel) return undefined;
      return vesselImage(vessel.aeCode, signal);
    },
    [vessel]
  );

  const getVesselAeCodes = useCallback(
    (activeOnly = false) => {
      if (!vessels?.length) return [];
      return vessels
        .filter(
          (v: Vessel) =>
            (!activeOnly || v.active) &&
            (roleName === 'AE Admin' || vesselCodes.indexOf(v.aeCode) >= 0)
        )
        .map((v: Vessel) => v.aeCode);
    },
    [vessels, roleName, vesselCodes]
  );

  const vesselAeCodes = useMemo(() => {
    if (!vessels?.length) return [];
    return vessels
      .filter(
        (v: Vessel) =>
          roleName === 'AE Admin' || vesselCodes.indexOf(v.aeCode) >= 0
      )
      .map((v: Vessel) => v.aeCode);
  }, [vessels, roleName, vesselCodes]);

  const mostCommonCurrency = useMemo((): string | null => {
    if (!vessels?.length) return null;
    const currencyCount: Record<string, number> = {};
    for (const v of vessels) {
      if (v.currency) {
        currencyCount[v.currency] = (currencyCount[v.currency] || 0) + 1;
      }
    }
    let mostFrequentCurrency: string | null = null;
    let maxCount = 0;

    for (const [currency, count] of Object.entries(currencyCount)) {
      if (count > maxCount) {
        mostFrequentCurrency = currency;
        maxCount = count;
      }
    }

    return mostFrequentCurrency;
  }, [vessels, roleName, vesselCodes]);

  const activeVesselCodes = useMemo(
    () => getVesselAeCodes(true),
    [getVesselAeCodes]
  );

  const allVesselCodes = useMemo(() => getVesselAeCodes(), [getVesselAeCodes]);

  const organizationIds = useMemo(() => {
    const orgIds = [];

    if (vessels?.length) {
      for (const v of vessels) {
        if (orgIds.indexOf(v.organizationId) < 0) {
          orgIds.push(v.organizationId);
        }
      }
    }

    return orgIds;
  }, [vessels]);

  const organizations = useMemo(() => {
    const orgs = [];

    if (vessels?.length) {
      for (const v of vessels) {
        if (orgs.indexOf(v.organizationId) < 0) {
          orgs.push({
            id: v.organizationId,
            name: v.organization
          });
        }
      }
    }

    return orgs;
  }, [vessels]);

  const getFosId = useCallback(
    (aeCode: string) => {
      if (vessels) {
        const theVessel = vessels.find((v: Vessel) => v.aeCode === aeCode);
        return theVessel ? theVessel.fosVesselId : null;
      }
      return null;
    },
    [vessels]
  );

  const fetchVessels = useCallback(
    async (standAlone = false, signal?: AbortSignal) => {
      const dataCheck = standAlone ? !allVessels : !allVessels || !vessels;
      if (vesselCodes && roleName && dataCheck) {
        await ctx.fetchVessels(
          roleName !== 'AE Admin' ? vesselCodes : null,
          signal
        );
      }
    },
    [vesselCodes, roleName, allVessels, vessels]
  );

  const userVessels = useMemo(() => {
    return vesselCodes && roleName !== 'AE Admin'
      ? vessels?.filter((v: Vessel) => vesselCodes?.indexOf(v.aeCode) >= 0)
      : vessels;
  }, [vesselCodes, vessels, roleName]);

  const isVesselActive = useCallback(
    (vesselCode: string) => {
      if (allVessels) {
        const theVessel = allVessels.find(
          (v: Vessel) => v.aeCode === vesselCode
        );
        return theVessel.active;
      }
      return false;
    },
    [allVessels]
  );

  return {
    ...ctx,
    activeVesselCodes,
    allVesselCodes,
    organizationIds,
    organizations,
    userVessels,
    vesselAeCodes,
    mostCommonCurrency,
    fetchVessels,
    vesselAvatar,
    getVesselAeCodes,
    getFosId,
    isVesselActive
  };
};
