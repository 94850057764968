export enum FileTypes {
  FOLDER = 'Folder',
  ROOT_FOLDER = 'Root Folder',
  GALLERY = 'Gallery',
  PDF = 'PDF',
  WORD = 'Word',
  IMAGE = 'Image',
  EXCEL = 'Excel',
  POWERPOINT = 'PowerPoint',
  AUDIO = 'Audio',
  VIDEO = 'Video',
  ARCHIVE = 'Archive',
  CODE = 'Code',
  BINARY = 'Binary',
  CSV = 'CSV',
  SPREADSHEET = 'SpreadSheet'
}

export enum Features {
  AERESOURCES = 'AERESOURCES',
  AERESOURCES_LEADERSHIP = 'AERESOURCES_LEADERSHIP',
  AERESOURCES_LOOKOUT = 'AERESOURCES_LOOKOUT',
  AERESOURCES_PSC_NEWSLETTERS = 'AERESOURCES_PSC_NEWSLETTERS',
  AERESOURCES_TECHNICAL_PAPERS = 'AERESOURCES_TECHNICAL_PAPERS',
  CERTIFICATES = 'CERTIFICATES',
  CREWS = 'CREWS',
  DOCUMENTS = 'DOCUMENTS',
  DRAWINGS = 'DRAWINGS',
  FINANCIAL = 'FINANCIAL',
  FINANCIAL_DASHBOARD = 'FINANCIAL_DASHBOARD',
  FINANCIAL_BUDGET = 'FINANCIAL_BUDGET',
  FINANCIAL_OPEX_COMMENT_PUBLISH = 'FINANCIAL_OPEX_COMMENT_PUBLISH',
  FINANCIAL_OPEX_FLEET_REPORT = 'FINANCIAL_OPEX_FLEET_REPORT',
  FINANCIAL_OPEX_VESSEL_REPORT = 'FINANCIAL_OPEX_VESSEL_REPORT',
  FINANCIAL_OTHER_REPORTS_FLEET = 'FINANCIAL_OTHER_REPORTS_FLEET',
  FINANCIAL_OTHER_REPORTS_VESSEL = 'FINANCIAL_OTHER_REPORTS_VESSEL',
  KPI = 'KPI',
  LOCATION_NAVIGATION = 'LOCATION_NAVIGATION',
  ADMIN = 'ADMIN',
  OPEX_REPORT = 'OPEX Report',
  REPORTS = 'REPORTS',
  REPORTS_CLAIMS = 'REPORTS_CLAIMS',
  REPORTS_COMMERCIAL = 'REPORTS_COMMERCIAL',
  REPORTS_EXTERNAL_INSPECTIONS = 'REPORTS_EXTERNAL_INSPECTIONS',
  REPORTS_INCIDENTS = 'REPORTS_INCIDENTS',
  REPORTS_NEARMISS = 'REPORTS_NEARMISS',
  REPORTS_INTERNAL_INSPECTIONS = 'REPORTS_INTERNAL_INSPECTIONS',
  REPORTS_GALLERY = 'REPORTS_GALLERY',
  REPORTS_DRYDOCK = 'REPORTS_DRY-DOCK',
  REPORTS_OTHER_REPORTS = 'REPORTS_OTHER_REPORTS',
  REPORTS_INTERNAL_AUDIT = 'REPORTS_INTERNAL_AUDITS',
  OUR_EXPERTS = 'OUR_EXPERTS'
}

export enum Systems {
  FOS = 'FOS',
  JIBE = 'JIBE',
  MICROSOFT365 = 'MICROSOFT 365'
}
