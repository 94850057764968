import { withTranslation } from 'react-i18next';

import { Provider as MessageBoxProvider } from '@context/MessageBox';
import StyleThemeProvider from './RestrictedArea/Providers/StyleThemeProvider';

import LanguageHandler from './LanguageHandler';
import PublicArea from './PublicArea';
import RestrictedArea from './RestrictedArea';

const App = () => {
  return (
    <MessageBoxProvider>
      <StyleThemeProvider>
        <LanguageHandler>
          <PublicArea />
          <RestrictedArea />
        </LanguageHandler>
      </StyleThemeProvider>
    </MessageBoxProvider>
  );
};

export default withTranslation()(App);
