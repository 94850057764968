import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthUser } from '@context/AuthUser';
import { useTranslator } from '@hooks/useTranslator';
import { getDefaultLanguage, getSupportedLanguages } from '@utils/index';

const AppRouter = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token, userLanguage, error } = useAuthUser();
  const {
    i18n: { changeLanguage }
  } = useTranslator();

  const handleChange = useCallback(
    (lang: string) => {
      const supported = getSupportedLanguages();
      const pathSplit = pathname.split('/');
      pathSplit.shift();
      if (supported.includes(pathSplit[0])) pathSplit.shift();

      navigate(
        `/${lang === 'en' || lang === '' ? '' : lang + '/'}${pathSplit.join(
          '/'
        )}`
      );
      changeLanguage(lang);
    },
    [pathname, changeLanguage, navigate]
  );

  useEffect(() => {
    const lang = userLanguage?.toLowerCase();
    if (lang && lang !== getDefaultLanguage()) {
      handleChange(lang);
    }
  }, [userLanguage, handleChange]);

  useEffect(() => {
    if (error) {
      let lang = '';
      lang = userLanguage ? userLanguage.toLowerCase() : lang;
      lang = lang === 'en' ? '' : `/${lang}`;
      navigate(`${lang}/login`);
    }
  }, [error, userLanguage]);

  return children;
};

export default AppRouter;
