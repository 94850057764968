import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { CONFIG } from '@library/config';
import {
  generateCodeChallenge,
  generateCodeVerifier,
  generateState,
  stateBase64Encode
} from '@utils/pkce';
import { refreshTokenPost } from '@utils/refreshTokenPost';
import { AUTH_STORAGE_KEY, CLIENT_ID, SCOPE } from './constants';
import type { AuthContextType, AuthType } from './types';

const { CODE_VERIFIER, IS_AUTHENTICATED, AUTH_STATE } = AUTH_STORAGE_KEY;
const { IDS_URI, HOST } = CONFIG;
const REDIRECT_URI = `${HOST}/callback`;

const handleAuthorization = (returnTo?: string, prompt = 'login') => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const state = stateBase64Encode(
    JSON.stringify({
      nonce: generateState(),
      returnTo: returnTo || '/'
    })
  );

  sessionStorage.setItem(CODE_VERIFIER, codeVerifier);
  sessionStorage.setItem(AUTH_STATE, state);

  const params = new URLSearchParams({
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
    scope: SCOPE,
    state,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    automatic_silent_renew: 'true',
    // silent_redirect_uri: `${HOST}/silent-renew.html`,
    response_mode: 'query',
    prompt
  });

  const authUrl = `${IDS_URI}/connect/authorize/callback?${params.toString()}`;
  window.location.href = authUrl;
};

const IDSAuthContext = createContext<AuthContextType | undefined>(undefined);

export const IDSAuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<AuthType>({
    isAuthenticated: false,
    isLoading: false,
    error: '',
    user: null
  });

  const handleError = (error: Error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    sessionStorage.removeItem(CODE_VERIFIER);
    sessionStorage.removeItem(AUTH_STATE);
    window.location.href = `${HOST}/error`;
  };

  const updateAuthStatus = useCallback(
    async (payload: {
      success: boolean;
      sub: string;
      access_token: string;
    }) => {
      localStorage.setItem(IS_AUTHENTICATED, payload.success.toString());

      setAuth({
        isAuthenticated: true,
        isLoading: false,
        error: '',
        user: null
      });
    },
    []
  );

  const fetchAccessToken = useCallback(
    async (code: string, receivedState: string) => {
      const storedState = sessionStorage.getItem(AUTH_STATE);
      const codeVerifier = sessionStorage.getItem(CODE_VERIFIER);

      if (receivedState !== storedState || !codeVerifier) {
        // return to login page if state or code verifier is missing
        handleAuthorization(window.location.pathname);
        return;
      }

      const params = new URLSearchParams({
        code,
        grant_type: 'authorization_code',
        redirect_uri: REDIRECT_URI,
        client_id: CLIENT_ID,
        code_verifier: codeVerifier
      });

      try {
        const response = await fetch(`${HOST}/api/auth/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: params.toString()
        });

        const data = await response.json();

        if (!data?.success) {
          setAuth({
            isAuthenticated: false,
            isLoading: false,
            error: "Oops! We couldn't log you in.",
            user: null
          });
          return;
        }

        await updateAuthStatus(data);

        // reset storage
        sessionStorage.removeItem(CODE_VERIFIER);
        sessionStorage.removeItem(AUTH_STATE);
      } catch (e) {
        handleError(e as Error);
      }
    },
    []
  );

  const refreshAccessToken = useCallback(async () => {
    const refreshToken = await refreshTokenPost(CLIENT_ID);

    if (!refreshToken) {
      throw new Error('Token refresh failed');
    }

    if (!refreshToken?.haveAccess) {
      throw new Error('FORBIDDEN');
    }

    updateAuthStatus(refreshToken);
  }, [handleAuthorization]);

  const value = useMemo(
    () => ({
      isAuthenticated: auth.isAuthenticated,
      isLoading: auth.isLoading,
      error: auth.error,
      user: auth.user,
      handleAuthorization,
      handleCallback: fetchAccessToken,
      handleError,
      refreshAccessToken,
      setAuth
    }),
    [
      auth.isAuthenticated,
      auth.isLoading,
      auth.error,
      auth.user,
      fetchAccessToken,
      refreshAccessToken,
      setAuth
    ]
  );

  return (
    <IDSAuthContext.Provider value={value}>{children}</IDSAuthContext.Provider>
  );
};

export const useIDSAuth = () => {
  const context = useContext(IDSAuthContext);

  if (!context) {
    throw new Error('useIDSAuth must wrapped with IDSAuthProvider');
  }

  return context;
};
