/* eslint-disable no-console */
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';

import { useConfig } from '@context/Config';

const useEncryption = () => {
  const { UI_URL_SECRET } = useConfig();

  const decrypt = (data: string): string => {
    if (!data) return '';
    try {
      return CryptoJS.AES.decrypt(data, UI_URL_SECRET).toString(
        CryptoJS.enc.Utf8
      );
    } catch (error) {
      console.error('Decryption error:', error);
      return '';
    }
  };

  const encrypt = (data: string): string => {
    if (!data) return '';
    try {
      return CryptoJS.AES.encrypt(data, UI_URL_SECRET).toString();
    } catch (error) {
      console.error('Encryption error:', error);
      return '';
    }
  };

  const encode64 = (data: string) => {
    if (!data) return '';
    return encodeURIComponent(Buffer.from(data).toString('base64'));
  };

  const decode64 = (data: string) => {
    if (!data) return '';
    try {
      return Buffer.from(decodeURIComponent(data), 'base64').toString();
    } catch (error) {
      console.error('Decoding error:', error);
      return '';
    }
  };

  const encodeCrypt = (data: string) => {
    if (!data) return '';
    return encodeURIComponent(encode64(encrypt(data)));
  };

  const decodeCrypt = (data: string) => {
    if (!data) return '';
    try {
      return decrypt(decode64(decodeURIComponent(data)));
    } catch (error) {
      console.error('Decode-decrypt error:', error);
      return '';
    }
  };

  return {
    decrypt,
    encrypt,
    encode64,
    decode64,
    encodeCrypt,
    decodeCrypt
  };
};

export default useEncryption;
