export const CLIENT_ID = 'myae_ship_ui';

export const SCOPE =
  'openid login.api offline_access am.read am.write sm.read sm.write';
export const AUTH_STORAGE_KEY = {
  IS_AUTHENTICATED: 'is_authenticated',
  CODE_VERIFIER: 'code_verifier',
  AUTH_STATE: 'auth_state'
};

export const SM_TOKEN = 'SM_TOKEN';
